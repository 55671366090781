<template>
  <div>
    <div class="content__top">
      <div>
        <Breadcrumbs
          :views="[
            {
              label: 'Valorización de Puestos',
              to: '/valorizacion-de-puestos/valorizacion',
            },
          ]"
          :currentView="{
            label: 'Mapa de Valorización y Puestos',
            to: '/valorizacion-de-puestos/mapa',
          }"
        />
        <h2>Mapa de Valorizaci&oacute;n de Puestos</h2>
      </div>
      <div class="content__buttons">
        <Menu direction="left">
          <template #label>
            <Button type="button" variant="secondary" size="xsmall">
              <unicon
                class="ellipsis"
                name="ellipsis-h"
                fill="currentColor"
                height="16px"
                width="16px"
              />
            </Button>
          </template>
          <template #options>
            <menu-item @click="exportToExcel">
              <unicon
                name="file-download"
                fill="currentColor"
                height="15.5px"
                width="15.5px"
              ></unicon>
              Exportar a Excel
            </menu-item>
          </template>
        </Menu>
      </div>
    </div>

    <div v-if="dataIsLoaded" class="table">
      <table ref="table">
        <tr>
          <th rowspan="3">#</th>
          <th rowspan="3">Puesto</th>
          <th rowspan="3">Total del Puesto</th>
          <th
            v-for="factor in factors"
            :key="factor.id"
            :colspan="factor.subfactors.length * 2 + 1"
            class="center"
          >
            {{ factor.name }}
          </th>
        </tr>

        <tr>
          <template v-for="factor in factors">
            <th
              colspan="2"
              v-for="subfactor in factor.subfactors"
              :key="`${subfactor.id}-name`"
              :title="subfactor.name"
            >
              {{ subfactor.name }}
            </th>
            <th rowspan="2" :key="`${factor.id}-total`">Total del Factor</th>
          </template>
        </tr>

        <tr>
          <template v-for="factor in factors">
            <template v-for="sf in factor.subfactors">
              <th :key="`${sf.id}-grade`">Grado</th>
              <th :key="`${sf.id}-points`">Pts.</th>
            </template>
          </template>
        </tr>

        <tr v-for="(position, index) in positions" :key="position.id">
          <td>{{ index + 1 }}</td>
          <td>{{ position.name }}</td>
          <td>{{ (position.evaluation && position.evaluation.total) || 0 }}</td>

          <template v-if="position.evaluation">
            <template v-for="factor in position.evaluation.evaluation">
              <template v-for="subfactor in factor.subfactors">
                <td :key="`${subfactor.id}-grade`">
                  {{ subfactor.selected.index || 0 }}
                </td>
                <td :key="`${subfactor.id}-points`">
                  {{ subfactor.selected.value || 0 }}
                </td>
              </template>

              <td :key="`${factor.id}-total`">{{ factor.value || 0 }}</td>
            </template>
          </template>

          <template v-else>
            <template v-for="factor in factors">
              <template v-for="subfactor in factor.subfactors">
                <td :key="`${subfactor.id}-grade`">0</td>
                <td :key="`${subfactor.id}-points`">0</td>
              </template>
              <td :key="`${factor.id}-total`">0</td>
            </template>
          </template>
        </tr>
      </table>
    </div>

    <LoadingScreen v-else />
  </div>
</template>

<script>
/* eslint-disable */
import { utils } from 'xlsx';
import * as XLSXS from 'xlsx-style';

import { mapActions, mapState } from 'vuex';

import Button from '@/components/buttons/Button.vue';
import Breadcrumbs from '@/components/Breadcrumbs.vue';
import LoadingScreen from '@/components/loading/LoadingScreen.vue';
import Menu from '@/components/menu/Menu.vue';
import MenuItem from '@/components/menu/MenuItem.vue';

export default {
  components: {
    Breadcrumbs,
    LoadingScreen,
    Menu,
    MenuItem,
    Button,
  },

  data: () => ({
    isLoading: false,
    dataIsLoaded: false,
  }),

  computed: {
    ...mapState('jobEvaluation', {
      positions: (state) => state.positions,
      evaluations: (state) => state.evaluations,
      factors: (state) => state.factors,
    }),

    allSubfactors() {
      if (!this.factors.length) return [];
      return this.factors.reduce((subfactors, factor) => [...subfactors, ...factor.subfactors], []);
    },
  },

  methods: {
    ...mapActions('jobEvaluation', ['getFactors', 'getEvaluations', 'getPositions']),

    saveBlobAsFile(name, blob) {
      const url = URL.createObjectURL(blob);

      const a = document.createElement('a');
      a.style.display = 'none';
      a.href = url;
      a.download = name;
      document.body.appendChild(a);
      a.click();
      setTimeout(() => {
        document.body.removeChild(a);
        window.URL.revokeObjectURL(url);
      }, 0);
    },

    s2ab(s) {
      let buf = new ArrayBuffer(s.length);
      let view = new Uint8Array(buf);
      for (let i = 0; i !== s.length; ++i) view[i] = s.charCodeAt(i) & 0xff;
      return buf;
    },
    async loadRequiredData() {
      try {
        this.isLoading = true;

        if (!this.positions.length) await this.getPositions();
        if (!this.evaluations.length) await this.getEvaluations();
        if (!this.factors.length) await this.getFactors();

        this.dataIsLoaded = true;

        console.log('Loaded data!');
      } catch (error) {
        console.log(`Something went wrong: ${error}`);
      } finally {
        this.isLoading = false;
      }
    },

    exportToExcel() {
      const $table = this.$refs.table;
      const sheet = utils.table_to_sheet($table);

      const workbook = {
        SheetNames: ['Mapa de Valorización de Puestos'],
        Sheets: { 'Mapa de Valorización de Puestos': sheet },
      };

      workbook.SheetNames.forEach((sheetName) => {
        const ws = workbook.Sheets[sheetName];
        const range = utils.decode_range(ws['!ref']);

        for (let C = range.s.c; C <= range.e.c; ++C) {
          const address = utils.encode_cell({ r: 0, c: C });
          if (!ws[address]) continue;
          ws[address].s = { fill: { fgColor: { rgb: '1A96FC' } } };
        }
      });

      const workbookout = XLSXS.write(workbook, {
        bookType: 'xlsx',
        type: 'binary',
      });

      this.saveBlobAsFile(
        `valorizacion_de_puestos.xlsx`,
        new Blob([this.s2ab(workbookout)], { type: 'application/octet-stream' }),
      );
    },
  },

  mounted() {
    this.loadRequiredData();
  },
};
</script>

<style lang="scss" scoped>
.table {
  max-width: 100%;
  width: 100%;
  margin-top: 2em;
  overflow: auto;
}

tr td:nth-child(2),
tr:first-child th:nth-child(2) {
  position: sticky;
  left: 0;
}

tr td:nth-child(2) {
  background-color: white;
}

th {
  max-width: 150px;
  text-overflow: ellipsis;
  overflow: hidden;

  &.center {
    text-align: center;
  }
}

.content__top {
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  margin-bottom: 1rem;
}

.content__buttons {
  display: flex;
  justify-content: flex-end;
  gap: 1em;
}
</style>
